import React, { useState, useEffect, useReducer, useMemo } from "react";
import ReactPaginate from "react-paginate";
import { Row, Col, Button, Spinner } from "react-bootstrap";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";

import request from "services/request";
import CustomTable from "components/customTable";
import SortFilter from "components/customTable/sortFilter";
import { cloneDeep } from "common/utils.ts";
import { useLoading } from "contexts/LoadingContextManagement";
import {
  DEBOUNCE_DELAY,
  DEFAULT_ERROR_MESSAGE,
  NEW_INGREDIENT_CATEGORIES,
  ITEMS_PER_PAGE,
} from "common/constants";
import { useUserData } from "contexts/AuthContextManagement";

import CustomModal from "./modal";
import DeleteModal from "views/commonViews/DeleteModal";
import reducer, { ACTION_TYPES, initialState } from "./reducer";
import { getIngredientsDummyData, getProviderIngredientsDummyData, getProvidersDummy, ingredientsKeys, providerIngredientsKeys, requiredIngredientsKeys, requiredProviderIngredientsKeys } from "../utils";

import Addicon from "assets/images/icon/ADD_WHITE.svg";
import Trashicon from "assets/images/icon/filter.svg";
import EyeIcon from "assets/images/icon/eye.svg";
import FilterIcon from "assets/images/icon/FILTER_ICON1.svg";

import "./index.scss";
import PriceHistory from "./PriceHistory";
import CustomProviderModal from "./providerModal";
import ImportModule from "../common/ImportIngredients";

function MyIngredients({ formData }) {
  const { t } = useTranslation();
  const { setLoading, setError } = useLoading();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [modalShow, setModalShow] = useState(false);
  const [importModalShow, setImportModalShow] = useState(false);
  const [fd, setfd] = useState({ orderBy: "desc", search: "" });
  const [selectedIngredient, setSelectedIngredient] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteModalShow, setDeleteModelShow] = useState(false);
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [isMyIngredients, setIsMyIngredients] = useState(false);
  const [options, setOptions] = useState(ingredientsKeys);
  const [requiredOptions, setRequiredOptions] = useState(requiredIngredientsKeys);
  const [allergens, setAllergens] = useState(null);
  const [ingredientsLoading, setIngredientsLoading] = useState(true);
  const [sleaveState, setSleaveState] = useState({
    index: -1,
    isOpen: true,
  });
  const {
    selectedRestaurantId,
    isRestaurantLoaded,
    hasRetaurants,
    isFilterShown,
    setFilterShown,
    isProviderIngredient,
    setProviderIngredient,
    setSampleRestaurantModal,
  } = useUserData();

  useEffect(() => {
    if (isMyIngredients) {
      setOptions(ingredientsKeys);
      setRequiredOptions(requiredIngredientsKeys);
    } else {
      setOptions(providerIngredientsKeys);
      setRequiredOptions(requiredProviderIngredientsKeys);
    }
  }, [isMyIngredients]);

  const myIngredientsTableColumns = [
    {
      // dataField: "name",
      caption: t("Ingredients"),
      className: "fw-bold",
      style: { width: "300px", display: "flex", height: "auto", whiteSpace: "normal", alignItems: "center" },
      headerStyle: { width: "150px" },
      type: "customRender",
      render: (_, it) => {
        const defaultProvider = it?.details?.find(i => i?.is_default)
       return <span>
          <span title={`${!defaultProvider ? "Provider Ingredient is missing": ""}`} style={{color: defaultProvider ? "": "orange"}}>{it?.name}</span> {!defaultProvider &&<span title="Provider Ingredient is missing" style={{color: "orange", cursor: "pointer"}}>&#9888;</span>}
        </span>
      }
    },
    // // {
    // //   dataField: "reference_name",
    // //   caption: t("ReferenceName"),
    // //   className: "text-center",
    // //   headerClassName: "text-center",
    // //   type: "string",
    // // },
    // {
    //   dataField: "format",
    //   caption: t("Format"),
    //   className: "text-center",
    //   headerClassName: "text-center",
    //   type: "string",
    // },
    // {
    //   dataField: "unit_price",
    //   caption: t("Unit price"),
    //   className: "text-center",
    //   headerClassName: "text-center",
    //   type: "number",
    // },
    // {
    //   dataField: "provider_name",
    //   caption: t("Provider"),
    //   className: "text-center",
    //   headerClassName: "text-center",
    //   type: "string",
    // },
    {
      dataField: "category",
      caption: t("Category"),
      className: "text-center",
      headerClassName: "text-center",
      type: "customRender",
      render: (_, it) => (
        <span>
          {t(
            NEW_INGREDIENT_CATEGORIES.find(({ id }) => id === it?.category)?.label
          )}
        </span>
      ),
    },
    {
      dataField: "unit",
      caption: t("Unit"),
      className: "text-center",
      headerClassName: "text-center",
      type: "customRender",
      render: (_, it) => {
       return <span>
          {t(it?.unit)}
        </span>
      }
    },
    {
      dataField: "cost_excl_tax",
      caption: t("UnitCost"),
      className: "text-center",
      headerClassName: "text-center",
      type: "customRender",
      render: (_, it) => {
       const defaultProvider = it?.details?.find(i => i?.is_default)?.provider_ingredient;
       return (
        <span>
        {/* {defaultProvider?.recipe_unit_quantity ? (it?.cost_excl_tax/ defaultProvider?.recipe_unit_quantity)?.toFixed(2) : null} */}
          {defaultProvider?.recipe_unit_quantity && it?.details[0]?.provider_ingredient?.price_excl_tax
            ? (it.details[0].provider_ingredient?.price_excl_tax / defaultProvider?.recipe_unit_quantity).toFixed(2)
            : null}
        </span>
      );
      }
    },
    {
      dataField: "action",
      caption: "",
      className: "text-center",
      headerClassName: "text-center",
      type: "customRender",
      render: (col, it) => (
        <img
          src={EyeIcon}
          className="cursor-pointer"
          alt="..."
          onClick={showRow(it)}
        />
      ),
    },
  ];

  const providerIngredientsTableColumns = [
    {
      dataField: "name",
      caption: t("Name"),
      className: "fw-bold",
      style: { width: "150px" },
      headerStyle: { width: "150px" },
      type: "string",
    },
    {
      dataField: "provider_name",
      caption: t("Provider"),
      className: "text-center",
      headerClassName: "text-center",
      type: "string",
    },
    {
      dataField: "conditioning_name_1",
      caption: t("ConditioningName"),
      className: "text-center",
      headerClassName: "text-center",
      type: "number",
    },
    {
      dataField: "price_excl_tax",
      caption: t("Price (without tax)"),
      className: "text-center",
      headerClassName: "text-center",
      type: "string",
    },
    {
      dataField: "recipe_unit",
      caption: t("RecipeUnit"),
      className: "text-center",
      headerClassName: "text-center",
      type: "string",
    },
    {
      dataField: "recipe_unit_quantity",
      caption: t("RecipeUnitQuantity"),
      className: "text-center",
      headerClassName: "text-center",
      type: "string",
    },
    {
      dataField: "action",
      caption: "",
      className: "text-center",
      headerClassName: "text-center",
      type: "customRender",
      render: (col, it) => (
        <img
          src={EyeIcon}
          className="cursor-pointer"
          alt="..."
          onClick={showRow(it)}
        />
      ),
    },
  ];

  // Invoke when user click to request another page.
  const handlePageClick = async (event) => {
    try {
      let ingredients;
      let result;
      setLoading(true);
      setIngredientsLoading(true);
      if(isMyIngredients){
        result = await getIngredients(event.selected + 1);
        ingredients = result.ingredients
      }else {
        result = await getProviderIngredients(event.selected + 1)
        ingredients = mapIngredientsProvider(result.provider_ingredients)
      }
      setCurrentItems(() => ingredients);
      setPageCount(result.total_pages);
      setCurrentPage(event.selected);
      setLoading(false);
      setIngredientsLoading(false);
    } catch (error) {
      console.log(error);
      setError(DEFAULT_ERROR_MESSAGE);
    }
  };

  useEffect(() => {
    if(selectedRestaurantId) {
      getInitialData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRestaurantId, isRestaurantLoaded]);

  useEffect(() => {
      fetchAndSetIngredients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fd.search, fd.sortby, fd.orderBy, selectedRestaurantId, isMyIngredients, formData]);

  const fetchAndSetIngredients = async () => {
    try {
      let ingredients = []
      let result = {};
      setIngredientsLoading(true);
      if (isRestaurantLoaded && !hasRetaurants) {
        if(isMyIngredients) {
          ingredients = getIngredientsDummyData()
        }else {
          ingredients = getProviderIngredientsDummyData()
        }
        if(fd.search) {
          ingredients = ingredients.filter(item => (item.name.toLowerCase().includes(fd.search.toLowerCase())))
        }
        if(fd.sortby) {
          ingredients = ingredients.sort((a, b) => (a[fd.sortby] > b[fd.sortby]) ? 1: -1)
        }
        result = {
          ingredients,
          total_pages: 1,
          page: 0,
        };
        setCurrentItems(ingredients);
        setPageCount(result.total_pages);
        setCurrentPage(result.page);
        setLoading(false);
        setIngredientsLoading(false);
        return
      }
      if(isMyIngredients) {
        result = await getIngredients(currentPage + 1);
        ingredients = result.ingredients;
        dispatch({ type: ACTION_TYPES.INGREDIENTS, ingredients });
      } else {
        result = await getProviderIngredients(currentPage + 1);
        ingredients = mapIngredientsProvider(result.provider_ingredients);
        dispatch({ type: ACTION_TYPES.PROVIDER_INGREDEINTS, ingredients });
      }
      setCurrentItems(() => ingredients);
      setPageCount(result.total_pages);
      setCurrentPage(result.page - 1);
      setLoading(false);
      setIngredientsLoading(false);
    } catch (error) {
      console.log(error);
      setError(DEFAULT_ERROR_MESSAGE);
    }
  };

  const getIngredients = (page = 1) => {
    const payload = {
      restaurant_id: selectedRestaurantId,
      limit: ITEMS_PER_PAGE,
      page,
      order_by: fd.orderBy.toUpperCase(),
      ...(fd.sortby && { sort_by: fd.sortby }),
      ...(fd.search && { search: fd.search }),
    };

    if (formData?.category?.category) {
        payload.category = formData.category.category;
    }
    if (formData.ingredients) {
        payload.ingredients = formData.ingredients;
    }
    if (formData.providers) {
        payload.providers = formData.providers;
    }
    // if (formData.providers) {
    //     payload.providers = formData.providers;
    // }

    return request.get(`ingredients`, payload);
  };

  const getProviderIngredients = (page = 1) => {
    const payload = {
      restaurant_id: selectedRestaurantId,
      limit: ITEMS_PER_PAGE,
      page,
      order_by: fd.orderBy.toUpperCase(),
      ...(fd.sortby && { sort_by: fd.sortby }),
      ...(fd.search && { search: fd.search }),
    };

    if (formData.providers) {
      payload.providers = formData.providers;
    }
    if (formData.provider_ingredients) {
      payload.provider_ingredients = formData.provider_ingredients;
    }
    if (formData?.category?.category) {
        payload.category = formData.category.category;
    }

    return request.get(`provider-ingredients`, payload);
  };

  const getAllergens = () => request.get("allergens");

  const getProviders = () => {
    if (isRestaurantLoaded && !hasRetaurants) {
      return getProvidersDummy();
    }
    return request.get(`providers?restaurant_id=${selectedRestaurantId}`);
  };

  const mapIngredientsProvider = (ingredients) => {
    return ingredients.map(({ provider, ...ingredient }) => ({
      provider_name: provider?.name,
      provider_id: provider?.id,
      ...ingredient,
    }));
  };

  const getInitialData = async () => {
    try {
      setLoading(true);
      setIngredientsLoading(true);
      const [allergens, providers] = await Promise.all([
        getAllergens(),
        getProviders(),
      ]);

      setAllergens(allergens.allergens)

      dispatch({
        type: ACTION_TYPES.MULTI,
        payload: {
          allergens: allergens.allergens,
          providers: providers.providers,
        },
      });
      setLoading(false);
      setIngredientsLoading(false);
    } catch (error) {
      setError(DEFAULT_ERROR_MESSAGE);
    }
  };

  const showRow = (row) => () => {
    setSelectedIngredient(row);
    setModalShow(true);
  };

  const onSearchChange = debounce((ev) => {
    setfd({ ...fd, search: ev.target.value });
  }, DEBOUNCE_DELAY);

  const onModalHide = () => {
    setModalShow(false);
    setSelectedIngredient(null)
  };

  const selectChange =
    (it) =>
    ({ target: { checked } }) => {
      const newSelectedRows = cloneDeep(selectedRows);
      if (checked) {
        setSelectedRows(() => [...newSelectedRows, it.id]);
      } else {
        setSelectedRows(() => newSelectedRows.filter((p) => p !== it.id));
      }
    };

  const selectAllRows = ({ target: { checked } }) => {
    setSelectedRows(() => (checked ? [...currentItems.map((i) => i.id)] : []));
  };

  const deleteRows = async () => {
    try {
      let path = isMyIngredients ? `/ingredients` : `/provider-ingredients`
      let optionsObj = isMyIngredients ? {ingredient_ids: selectedRows } : {provider_ingredient_ids: selectedRows}
      const result = await request.delete(path, optionsObj);
      if (result.status === 200) {
        setSelectedRows([]);
        setCurrentItems(() =>
          currentItems.filter((i) => !selectedRows.includes(i.id))
        );
      }
    } catch (error) {
      console.log(error);
      setError(DEFAULT_ERROR_MESSAGE);
    }
  };

  const onRowAdded = () => {
    fetchAndSetIngredients();
    setSelectedIngredient(null);
  };

  const handleDoubleClick = (index, isOpen) => {
    if(isOpen) {
      setSleaveState(p => ({...p, isOpen: false}))
      setTimeout(() => {
        setSleaveState(p => ({...p, index: -1}))
      }, 300)
    } else {
      setSleaveState(p => ({...p, index, isOpen: true }))
    }
  }

  let processedCurrentItems = useMemo(() => {
    const newCurrentItems = [...currentItems];
    if(sleaveState.index !== -1) {
      // newCurrentItems.splice(sleaveState.index+1, 0, {prediction:{}, isOpen: sleaveState.isOpen})
      newCurrentItems[sleaveState.index] = {...newCurrentItems[sleaveState.index], prediction: { isOpen: sleaveState.isOpen }};
    }
    return newCurrentItems;
  }, [currentItems, sleaveState])

  if(sleaveState.index === -1 && isRestaurantLoaded && !hasRetaurants) {
    processedCurrentItems = currentItems
  }


  const modalTitle = isMyIngredients ? 'AddMultipleIngredients' : 'AddMultipleProviderIngredients'
  const requestPath = isMyIngredients ? '/ingredients/create-in-bulk' : '/provider-ingredients/create-in-bulk'
  const modalName = isMyIngredients ? 'Ingredient' : 'ProviderIngredient'

  const handleProviderIngredientsClick = () => {
    setIsMyIngredients(false);
    setProviderIngredient(true);
  };

  const handleIngredientsClick = () => {
    setIsMyIngredients(true);
    setProviderIngredient(false);
  }

  return (
    <div className="my-ingredients">
      {isMyIngredients ? <CustomModal
        show={modalShow}
        onHide={onModalHide}
        onRowAdded={onRowAdded}
        data={{ ...state, ...(selectedIngredient && { selectedIngredient }) }}
      /> :
      <CustomProviderModal 
        show={modalShow}
        onHide={onModalHide}
        onRowAdded={onRowAdded}
        data={{ ...state, ...(selectedIngredient && { selectedIngredient }) }} 
      />}
      
      <ImportModule allergens={allergens} show={importModalShow} modalName={modalName} requestPath={requestPath} options={options} requiredOptions={requiredOptions} title={modalTitle} onHide={setImportModalShow} providers={state.providers} onRowAdded={onRowAdded} />
      <DeleteModal
        show={deleteModalShow}
        onHide={() => setDeleteModelShow(false)}
        onPositiveClicked={() => {
          setDeleteModelShow(false);
          deleteRows();
        }}
        modalData={{
          title: t(`Delete ${(modalName).toLocaleLowerCase()}s`),
          description: t(
            `Are you sure you want to delete ${(modalName).toLocaleLowerCase()}s ? You cannot undo this action.`
          ),
          positiveBtnTitle: t(`Yes, delete ${(modalName).toLocaleLowerCase()}s`),
        }}
      />
      {/* <Row>
        <Col lg={{ span: 3 }}>
          <p className="title-text">{t("Ingredients")}</p>
          <p className="sub-title-text">{t("MyIngredientList")}</p>
        </Col>
      </Row> */}
      <Row>
       <div>
        <ul className="navbtns mb-0">
          <li className={`${!isMyIngredients ? "active" : ""}`}>
            <button
              className={`nav-link ${!isMyIngredients ? "active" : ""}`}
            //   onClick={() => setIsMyIngredients(false)}
                onClick={handleProviderIngredientsClick}
            >
              {t("ProviderIngredients")}
            </button>
          </li>
          <li className={`${isMyIngredients ? "active" : ""}`}>
            <button
              className={`nav-link ${isMyIngredients ? "active" : ""}`}
              // onClick={() => setIsMyIngredients(true)}
              onClick={handleIngredientsClick}
            >
              {t("MyIngredients")}
            </button>
          </li>
        </ul>
        </div>
      </Row>
      <Row>
        <div className="d-flex row" style={{marginTop: "50px"}}>
          <Col xs="auto">
            <Button
              variant="primary add-btn-container"
              onClick={() => setModalShow(true)}
            >
              <img src={Addicon} className="add-btn-icon" alt="..." />
              {t("AddIngredient")}
            </Button>
          </Col>
          <Col xs="auto">
            <Button
              variant="primary add-btn-container"
              onClick={() => setImportModalShow(true)}
            >
              <img src={Addicon} className="add-btn-icon" alt="..." />
              {t("AddMultipleIngredient")}
            </Button>
          </Col>

          <Col className="sort-container d-flex justify-content-end">
            {Boolean(selectedRows.length) && (
              <img
                src={Trashicon}
                className="me-3"
                onClick={() => {
                  if (isRestaurantLoaded && !hasRetaurants) {
                    setSampleRestaurantModal(true);
                    return;
                  }
                  setDeleteModelShow(true);
                }}
                alt="..."
              />
            )}
            <input
              type="search"
              className="search-input me-3"
              placeholder={t("Search")}
              onChange={onSearchChange}
            />
            <SortFilter
              cols={isMyIngredients ? myIngredientsTableColumns: providerIngredientsTableColumns}
              fd={fd}
              setfd={setfd}
              rootClassName="sort-filter"
            />

            {!isFilterShown && (
              <div className="headerbtns ms-3">
                <button
                  onClick={() => setFilterShown(true)}
                  className="btn btn-white btn-icon btn-theme"
                  style={{
                    height:"35px"
                  }}
                >
                  <img src={FilterIcon} alt="" className="m-0" />
                </button>
              </div>
            )}
          </Col>
        </div>
      </Row>
      <Row className="mt-3 table-container">

      {isMyIngredients && (
        ingredientsLoading ? (
          <div className="w-100 d-flex justify-content-center">
            <Spinner animation="border" variant="primary" />
          </div>
        ) : (
          <CustomTable
            columns={myIngredientsTableColumns}
            data={processedCurrentItems}
            selectChange={selectChange}
            selectedProducts={selectedRows}
            selectAllProducts={selectAllRows}
          />
        )
      )}

        {/* PROVIDER INGREDIENTS */}
         {!isMyIngredients && (
          ingredientsLoading ? (
            <div className="w-100 d-flex justify-content-center">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <CustomTable
              columns={providerIngredientsTableColumns}
              data={processedCurrentItems}
              selectChange={selectChange}
              selectedProducts={selectedRows}
              selectAllProducts={selectAllRows}
              onRowDoubleClick={handleDoubleClick}
              SleaveContent={PriceHistory}
          />
          )
        )}
        {
          !ingredientsLoading && (
            <Row>
              <div className="d-flex justify-content-end mt-2">
                <ReactPaginate
                  nextLabel={`${t("Next")}   >`}
                  forcePage={currentPage}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  pageCount={pageCount}
                  previousLabel={`<   ${t("Back")}`}
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active pagination-page-active"
                  renderOnZeroPageCount={null}
                />
              </div>
            </Row>
          )
        }
         
      </Row>
    </div>
  );
}

export default MyIngredients;
