import { useState, useCallback, useRef, useEffect } from "react";
import { Button, Modal, Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DragDrop from "./DragDrop";
import Imported from "./Imported";
import { read, utils } from "xlsx";
import request from "services/request";
import { useUserData } from "contexts/AuthContextManagement";
import Trashicon from "assets/images/icon/filter.svg";
import DeleteModal from "views/commonViews/DeleteModal";
import Uploaded from "./Uploaded";
import { toast } from "react-toastify";
import { ALLERGENS_TRANSLATIONS, CATEGORY_TRANSLATIONS, MEAL_TYPE_TRANSLATIONS, STORAGE_UNIT_TRANSLATIONS, columnMapping, getImportedFileFormatData, translateFrenchToEnglish } from "../utils";

function ImportModule({ show, modalName, onHide, title, options, requiredOptions, requestPath, onRowAdded }) {
  const { t  } = useTranslation();
  const [state, setState] = useState({
    sumbitEnabled: true,
    rows: [],
    stepIndex: 0,
    selectedRows: {},
    optionsVisible: false,
    deleteModal: false,
    providerValMsg: undefined,
    ingredients: [],
    providers: [],
    fileValMsg: undefined,
    uploaded: false,
    responseMessage: undefined,
    errors: [],
  });
  const { selectedRestaurantId } = useUserData();
  const localOptions = useRef(options)
  const localRequiredOptions = useRef(requiredOptions)
  const localModalTitle = useRef(title)

  useEffect(() => {
      localOptions.current = options
      localRequiredOptions.current = requiredOptions
      localModalTitle.current = title
  }, [options, requiredOptions, title])

  const onDropAccepted = useCallback((_acceptedFiles) => {
    const file = _acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = async (event) => {
      const wb = read(event.target.result);
      const sheets = wb.SheetNames;
      
      if (sheets.length) {
        let rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
        if(sheets.length > 1) {
          if(localModalTitle.current === 'AddMultipleIngredients'){ 
            rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          } else if (localModalTitle.current === 'AddMultipleProviderIngredients') {
            rows = utils.sheet_to_json(wb.Sheets[sheets[1]]);
          } else if (localModalTitle.current === 'AddMultipleProviders'){
            rows = utils.sheet_to_json(wb.Sheets[sheets[2]]);
          } else if (localModalTitle.current === 'AddMultipleMeals'){
            rows = utils.sheet_to_json(wb.Sheets[sheets[3]]);
          }
        }

      if (rows.length) {
          rows = columnMapping(localOptions.current, rows);
          const optionKeys = Object.keys(localOptions.current)
          rows = rows.map((item) => {
            optionKeys.forEach(
              (property) => {
                if (typeof item[property] == 'string' && item[property]?.includes(",")) {
                  let updatedProperty = item[property].replace(",", ".");
                  if(!isNaN(updatedProperty)) {
                    item[property] = parseFloat(updatedProperty);
                  }
                }
              });
              if(localModalTitle.current === 'AddMultipleIngredients'){ 
                item = {...item, allergens: item.allergens?.toLowerCase(), category: translateFrenchToEnglish(item.category, CATEGORY_TRANSLATIONS)}
              }
              if(localModalTitle.current === 'AddMultipleMeals'){ 
                item = {...item, type: translateFrenchToEnglish(item.type, MEAL_TYPE_TRANSLATIONS)}
              }
              if(localModalTitle.current === 'AddMultipleProviderIngredients' && item['recipe_unit'] === item['conditioning_unit_1']){
                if(item['conditioning_quantity_1'] && !item['recipe_unit_quantity']){
                  item['recipe_unit_quantity'] = item['conditioning_quantity_1']
                } else if(item['recipe_unit_quantity'] && !item['conditioning_quantity_1']){
                  item['conditioning_quantity_1'] = item['recipe_unit_quantity']
                }
              }
              // if(localModalTitle.current === 'AddMultipleProviderIngredients') {
              //   if(item['storage_unit']){
              //     item['storage_unit'] = translateFrenchToEnglish((item['storage_unit']?.charAt(0).toUpperCase() + item['storage_unit'].slice(1).toLowerCase()).trim(), STORAGE_UNIT_TRANSLATIONS)
              //   }
              // }
              return item;
          });

          const data = getImportedFileFormatData(rows, false, localModalTitle, t);
          setState((prev) => ({
            ...prev,
            rows: transformExcelData(data.rows),
            stepIndex: 1,
            errors: data.error?.length ? data.error : [],
            fileValMsg: undefined,
          }));
        } else {
          setState((prev) => ({
            ...prev,
            fileValMsg: "This file is empty. No data found.",
          }));
        }
      }
    };
    reader.readAsArrayBuffer(file);
  }, []);

  const transformExcelData = (rows) => {
    const result = [Object.keys(rows[0])];
    for (let i = 0; i < rows.length; i++) {
      const element = rows[i];
      const temp = [];
      for (const key in element) {
        temp.push(element[key]);
      }
      result.push(temp);
    }
    return result;
  }

  const handleHeaderSelect = (value, index) => {
    setState((prev) => {
      const newState = { ...prev };
      newState.rows[0][index] = value;
      newState.fileValMsg = checkDataValidity();
      return newState;
    });
  };

  const handleHide = () => {
    setState((prev) => ({
      ...prev,
      rows: [],
      stepIndex: 0,
      selectedRows: {},
      optionsVisible: false,
      deleteModal: false,
      providerValMsg: undefined,
      fileValMsg: undefined,
      ingredients: [],
      providers: [],
      uploaded: false,
    }));
    onHide(false);
  };

  const createPayload = () => {
    let result = [];
    for (let i = 1; i < state.rows.length; i++) {
      const element = state.rows[i];
      const obj = {};
      for (let j = 0; j < state.rows[0].length; j++) {
        if(options[state.rows[0][j]]) {
          const head = state.rows[0][j];
          obj[head] = (element[j]?.toString())
          if(head === 'cost_excl_tax'){
            obj[head] = element[j] || 0;
          }
          if(head === 'unit' || head === 'ingredient_unit' || head === 'recipe_unit' || head === 'conditioning_unit_1' || head === 'conditioning_unit_2') {
            if(element[j] === 'piece'){
              obj[head] = 'item';
            }else{
              obj[head] = element[j]?.toLowerCase()
            }
          }

          if(head === "type") {
            obj[head] = translateFrenchToEnglish(element[j], MEAL_TYPE_TRANSLATIONS)
          }

          if(head === 'category') {
            obj[head] = translateFrenchToEnglish(element[j], CATEGORY_TRANSLATIONS)
          }
          if(head === 'allergens') {
            obj[head] = element[j] ? new Array(translateFrenchToEnglish(element[j], ALLERGENS_TRANSLATIONS)) : null
          }
          if(head === 'provider_reference_number') {
            obj[head] = element[j]?.toString() || null
          }
          // Convert "OUI" / "NON" to true / false for eat_in and take_away
          if (head === 'eat_in' || head === 'take_away') {
            obj[head] = element[j] === 'OUI' ? true : false;
          }
          }
        }
        // Explicitly set 'ingredient' field when 'title' is 'AddMultipleProviderIngredients'
        if (title === 'AddMultipleProviderIngredients') {
          const nameIndex = state.rows[0].indexOf('name');
          if (nameIndex !== -1) {
              // Set the 'ingredient' to the value of the 'name' field
              obj['ingredient'] = element[nameIndex];
          }
         }

        // Add the value or default to 0 if missing
        if (title === 'AddMultipleIngredients') {
          obj['cost_excl_tax'] = element[state.rows[0].indexOf('cost_excl_tax')] || 0;
        }
        obj.restaurant_id = selectedRestaurantId;
        result.push(obj);
    }
    return result;
  };

  const checkDataValidity = () => {
    const requiredKeys = Object.keys(requiredOptions);
    const head = state.rows[0];
    let names = "";
    let message = undefined;
    for (const key of requiredKeys) {
      if (!head.includes(key)) {
        names += ` ${requiredOptions[key]},`;
      }
    }
    if (Boolean(names)) {
      names = names.slice(0, -1);
      message =
        "These fields are required" +
        names +
        ". Make sure you have selected these fields.";
    }

    return message;
  };

  const validated = () => {
    let errors = {};
    if (!state.rows.length) {
      errors.fileValMsg = "Please select an excel file first.";
    }

    if (state.stepIndex === 1) {
      const msg = checkDataValidity();
      if (msg) {
        errors.fileValMsg = msg;
      }
    }

    const hasErrors = Object.keys(errors).length;
    if (hasErrors) {
      setState((prev) => ({ ...prev, ...errors }));
    }
    return !hasErrors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validated()) {
      return;
    }
    setState(p => ({...p, sumbitEnabled: false }));
    try {
      let payload
      if (title === 'AddMultipleIngredients') {
         payload = { ingredients: createPayload() }
      } else if(title === 'AddMultipleProviderIngredients') {
        payload = { provider_ingredients: createPayload() };
      } else if(title === 'AddMultipleMeals') {
        payload = {meals: createPayload()}
      } else {
        payload = {providers: createPayload()}
      }

     const result = await request.post(requestPath, payload);
      if ([200, 201].includes(result.status)) {
        const response =await result.json();
        setState((prev) => (
          { 
          ...prev, uploaded: true,
          responseMessage: response.msg,
          ingredients: response.newIngredients,
          providers: response.newProviders,
          sumbitEnabled: true 
        }));
        onRowAdded();
        return;
      }
      const errRes = (await result.json()).msg
      if(errRes.length && title === 'AddMultipleProviders' && errRes[0] === "Email address already in use.") {
        toast.error(t(`${errRes}`));
      } else if(errRes.length) {
        toast.error(t("Something went wrong. Please validate your sheet data"));
      }
      setState(p => ({...p, sumbitEnabled: true }));
    } catch (error) {
      toast.error("Something went wrong");
      setState(p => ({...p, sumbitEnabled: true }));
    }
  };

  // const handleChange = (key) => (value) => {
  //   setState((prev) => ({
  //     ...prev,
  //     selectedProvider: value,
  //     providerValMsg: undefined,
  //   }));
  // };

  const handleSelectRow = (checked, rowKey) => {
    setState((prev) => {
      const newState = { ...prev };
      newState.selectedRows[rowKey] = checked;
      newState.optionsVisible = !Object.keys(newState.selectedRows).every(
        (v) => newState.selectedRows[v] === false
      );
      return newState;
    });
  };

  const deleteSelected = () => {
    setState((prev) => ({ ...prev, deleteModal: true }));
  };

  
const setErrors = (errors) => {
  setState((prev) => ({ ...prev, errors }))
}

const setData = (rows) => {
  const data = getImportedFileFormatData(rows, true, localModalTitle, t);
  setState((prev) => ({ ...prev, rows, errors: data.error }))
}

  const deleteConfirmed = () => {
    const newState = { ...state };
    const keys = Object.keys(newState.selectedRows);
    for (const element of keys.reverse()) {
      if (newState.selectedRows[element]) {
        newState.rows.splice(element, 1);
      }
    }

    const data = getImportedFileFormatData(newState.rows, true, localModalTitle, t);
    setState((prev) => ({
      ...prev,
      rows: newState.rows,
      errors: data.error?.length ? data.error : [],
      selectedRows: {},
      optionsVisible: false,
      deleteModal: false
    }));  
  };

  const renderBody = () => (
    <>
      <Modal.Header className="add-restaurants-modal-header" closeButton>
        <Modal.Title className="add-restaurants-modal-title">
          {t(`${title}`)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="justify-content-between">
          {/* <Col>
            <span className="add-restaurants-input-title ps-0">
              {t("Provider")}
            </span>
            <SelectInput
              dataField="provider_id"
              placeholder={t("SelectProvider")}
              options={providers?.map((p) => ({
                ...p,
                label: p.name,
                value: p.id,
              }))}
              onChange={handleChange}
              value={state.selectedProvider}
              validated={false}
            />
            <Form.Text className="text-danger">
              {state.providerValMsg}
            </Form.Text>
          </Col> */}
          <Col className="text-end">
            {state.optionsVisible && (
              <img
                src={Trashicon}
                className="mt-4"
                onClick={deleteSelected}
                alt="..."
              />
            )}
          </Col>
        </Row>
        {state.stepIndex === 0 && <DragDrop modalName={modalName} onDropAccepted={onDropAccepted} />}
        {state.stepIndex === 1 && (
          <Imported
            rows={state.rows}
            selectedRows={state.selectedRows}
            selectHeader={handleHeaderSelect}
            handleSelectRow={handleSelectRow}
            errors={state.errors}
            setErrors={setErrors}
            setData={setData}
            dropdownOptions={options}
            allergens={state.allergens}
          />
        )}
        <Form.Text className="text-danger">{state.fileValMsg}</Form.Text>
      </Modal.Body>
      <Modal.Footer className="add-restaurants-modal-footer">
        <Button
          type="submit"
          disabled={!state.sumbitEnabled}
          className="add-restaurant-confirm-btn"
          onClick={handleSubmit}
        >
          {t("Confirm")}
        </Button>
      </Modal.Footer>
    </>
  );

  return (
    <Modal
      show={show}
      onHide={handleHide}
      size="xl"
      centered
      className="add-ingredient"
      backdropClassName="add-ingredient-backdrop"
    >
      <DeleteModal
        show={state.deleteModal}
        onHide={() => setState((prev) => ({ ...prev, deleteModal: false }))}
        onPositiveClicked={deleteConfirmed}
        modalData={{
          title: t(`Delete ${(modalName).toLowerCase()}s`),
          description: t(
            `Are you sure you want to delete ${(modalName).toLowerCase()}s ? You cannot undo this action.`
          ),
          positiveBtnTitle: t(`Yes, delete ${(modalName).toLowerCase()}s`),
        }}
      />
      {!state.uploaded && renderBody()}
      {state.uploaded && <Uploaded responseMessage={state.responseMessage} modalName={modalName} ingredients={state.ingredients} providers={state.providers} onHide={handleHide} t={t} />}
    </Modal>
  );
}

export default ImportModule;
