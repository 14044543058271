import React, { useState, useEffect, useReducer } from "react";
import { useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { Row, Col, Dropdown, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { set } from "lodash";

import CustomTable from "components/customTable";
import SortFilter from "components/customTable/sortFilter";
import { useLoading } from "contexts/LoadingContextManagement";
import { DEFAULT_ERROR_MESSAGE, ITEMS_PER_PAGE } from "common/constants";
import { useUserData } from "contexts/AuthContextManagement";
import { cloneDeep } from "common/utils.ts";
import { getDummyOrders } from "../../settings/utils";
import providersData from "../../../data/providers.json"
import orderData from "../../../data/sales_per_restaurant.json"
import FilterIcon from "assets/images/icon/FILTER_ICON1.svg";

import "../index.scss";

let isMountLoaded = false;

function MySales() {
  const { t } = useTranslation();
  const { setError } = useLoading();
  const [fd, setfd] = useState({ orderBy: "desc", search: "" });
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [orderDetailsModalShow, setOrderDetailsModalShow] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState([]);
  const [providers, setProviders] = useState([]);
  const [changedOrders, setChangedOrders] = useState([]);

  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState([]);
  const [originalItems, setOriginalItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const { selectedRestaurantId, isRestaurantLoaded, hasRetaurants, isFilterShown, setFilterShown } = useUserData();

  useEffect(() => {
    isMountLoaded = true;
  }, []);

  const tableColumns = [
    {
      dataField: "name",
      caption: t("Food name"),
      className: "fw-bold",
      style: { width: "150px" },
      headerStyle: { width: "150px" },
      type: "string",
    },
    {
      dataField: "restaurant2",
      caption: t("Mezzo Opéra"),
      className: "text-center",
      headerClassName: "text-center",
      type: "string",
    },
    {
      dataField: "restaurant3",
      caption: t("Mezzo Chatelet"),
      className: "text-center",
      headerClassName: "text-center",
      type: "number",
    },
    {
      dataField: "restaurant4",
      caption: t("Mezzo Vavin"),
      className: "text-center",
      headerClassName: "text-center",
      type: "number",
    },
    {
      dataField: "restaurant5",
      caption: t("Mezzo Odéon"),
      className: "text-center",
      headerClassName: "text-center",
      type: "number",
    },
    ,
    {
      dataField: "restaurant6",
      caption: t("Mezzo St Michel"),
      className: "text-center",
      headerClassName: "text-center",
      type: "number",
    }
  ];

  useEffect(() => {
    if (isRestaurantLoaded && !hasRetaurants) {
      setProviders(providersData);
      setCurrentItems(orderData?.sales);
      setCurrentPage(getDummyOrders().page - 1);
      return;
    }
  }, [selectedRestaurantId, isRestaurantLoaded, currentItems]);

  useEffect(() => {
    if (!isMountLoaded || selectedRestaurantId === "") {
      let updatedItems = [...originalItems];
      // Filter by provider
      if (selectedProvider) {
        const providerName = selectedProvider.name.toLowerCase();
        updatedItems = updatedItems.filter(
          (item) => item.provider.name.toLowerCase() === providerName
        );
      }
  
      // Filter by search term
      if (fd.search) {
        updatedItems = updatedItems.filter((item) =>
          item.order_number.toLowerCase().includes(fd.search.toLowerCase())
        );
      }
  
      // Sort by specified field
      if (fd.sortby) {
        updatedItems.sort((a, b) => {
          const aValue = a[fd.sortby]?.toString().toLowerCase();
          const bValue = b[fd.sortby]?.toString().toLowerCase();
  
          if (aValue < bValue) return -1;
          if (aValue > bValue) return 1;
          return 0;
        });
      }
  
      setCurrentItems(updatedItems);
      return;
    }
  }, [
    fd.search,
    fd.sortby,
    fd.orderBy,
    selectedRestaurantId,
    selectedProvider,
    originalItems
  ]);

  const onRowSelectChanged = (col, rowData) => (ev) => {
    const result = col.options.find((o) => o.id === ev);
    if (!result) {
      return;
    }
    const mapping = {
      accept: "Accept",
      refuse: "refuse",
    };
    const newFilteredData = cloneDeep(currentItems);
    const isExist = newFilteredData.find((f) => f.id === rowData.id);
    if (isExist) {
      set(isExist, col.dataField, mapping[result.id]);
      isExist.state = "update";
      setCurrentItems(newFilteredData);

      let isUpdated = changedOrders.find((c) => c.id === rowData.id);
      if (isUpdated) {
        let _changedOrders = [...changedOrders];
        _changedOrders.every((value) => {
          if (value.id === rowData.id) {
            value.is_delivered = mapping[result.id] === "Accept";
            return false;
          }
          return true;
        });
        setChangedOrders(_changedOrders);
      } else {
        let _data = {
          id: rowData.id,
          is_delivered: mapping[result.id] === "Accept",
        };
        setChangedOrders([...changedOrders, _data]);
      }
    }
  };

  const onSearchChange = (ev) => {
    setfd({ ...fd, search: ev.target.value });
  };

  const selectAllRows = ({ target: { checked } }) => {
    setSelectedRows(() => (checked ? [...currentItems.map((f) => f.id)] : []));
  };

  const selectChange = (it) => ({ target: { checked } }) => {
      const newSelectedProducts = JSON.parse(JSON.stringify(selectedRows));
      if (checked) {
        setSelectedRows(() => [...newSelectedProducts, it.id]);
      } else {
        setSelectedRows(() => newSelectedProducts.filter((p) => p !== it.id));
      }
    };

  // Invoke when user click to request another page.
  const handlePageClick = async (event) => {
    try {
    //   const result = await getOrders(event.selected + 1);
    //   result.purchase_orders.forEach((value) => {
    //     value.delievry_status = value.is_delivered
    //       ? "Delivered"
    //       : "Not Delivered";
    //   });
    //   setCurrentItems(() => result.purchase_orders);
    //   setPageCount(result.total_pages);
    //   setCurrentPage(event.selected);
    } catch (error) {
      console.log(error);
      setError(DEFAULT_ERROR_MESSAGE);
    }
  };

  const onUpdateRow = (orderData) => {
    setSelectedOrder(orderData);
    setOrderDetailsModalShow(true);
  };

  const onReportRow = (orderData) => {
    setSelectedOrder(orderData);
  };

  return (
    <div className="my-orders">
      <Row>
        <Col className="sort-container d-flex justify-content-end">
          <input
            type="search"
            className="search-input me-3"
            placeholder={t("Search")}
            onChange={onSearchChange}
          />

          <SortFilter
            cols={tableColumns.slice(0, 3)}
            fd={fd}
            setfd={setfd}
            rootClassName="sort-filter ps-3"
          />

          {!isFilterShown && (
              <div className="headerbtns ms-3">
                <button
                  onClick={() => setFilterShown(true)}
                  className="btn btn-white btn-icon btn-theme"
                  style={{
                    height:"35px"
                  }}
                >
                  <img src={FilterIcon} alt="" className="m-0" />
                </button>
              </div>
            )}
        </Col>
      </Row>

      <Row className="mt-3 table-container">
        <CustomTable
          columns={tableColumns}
          data={currentItems}
          selectChange={selectChange}
          selectedProducts={selectedRows}
          selectAllProducts={selectAllRows}
          onRowSelectChanged={onRowSelectChanged}
          onUpdateRow={onUpdateRow}
          onReportRow={onReportRow}
        />

        <Row>
          <div className="d-flex justify-content-end mt-2">
            <ReactPaginate
              nextLabel={`${t("Next")}   >`}
              onPageChange={handlePageClick}
              forcePage={currentPage}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel={`<   ${t("Back")}`}
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </div>
        </Row>
      </Row>
    </div>
  );
}

export default MySales;