import React, { useState, useEffect, useReducer } from "react";
import { useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { Row, Col, Dropdown, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { set } from "lodash";

import CustomTable from "components/customTable";
import SortFilter from "components/customTable/sortFilter";
import { useLoading } from "contexts/LoadingContextManagement";
import { DEFAULT_ERROR_MESSAGE, ITEMS_PER_PAGE } from "common/constants";
import { useUserData } from "contexts/AuthContextManagement";
import { cloneDeep } from "common/utils.ts";
import EyeIcon from "assets/images/icon/eye.svg";
import reducer, { ACTION_TYPES, initialState } from "../../stock/reducer";

import { getDummyOrders } from "../../settings/utils";
import providersData from "../../../data/providers.json"
import orderData from "../../../data/orders.json"
import CustomModal from "./orderModel";
import "../index.scss";

const getDeleiveryStatusTextColor = (text) => {
  let str = "text-";
  if (text === "Accept") {
    str += "accept";
  } else if (text === "Refuse") {
    str += "refuse";
  } else if (text === "Send") {
    str += "send";
  }
  return str;
};

let isMountLoaded = false;

function MyOrders() {
  const { t } = useTranslation();
  const { setLoading, setError } = useLoading();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [fd, setfd] = useState({ orderBy: "desc", search: "" });
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [orderDetailsModalShow, setOrderDetailsModalShow] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState([]);
  const [providers, setProviders] = useState([]);
  const [changedOrders, setChangedOrders] = useState([]);
  const [selectedIngredient, setSelectedIngredient] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [changedRows, setChangedRows] = useState([]);

  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState([]);
  const [originalItems, setOriginalItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const { selectedRestaurantId, isRestaurantLoaded, hasRetaurants } =
    useUserData();
  const history = useHistory();
  useEffect(() => {
    isMountLoaded = true;
  }, []);

  const tableColumns = [
    {
      dataField: "order_number",
      caption: t("Restaurant"),
      className: "fw-bold",
      style: { width: "150px" },
      headerStyle: { width: "150px" },
      type: "string",
    },
    {
      dataField: "estimated_delivery_date",
      caption: t("OrderDate"),
      className: "text-center",
      headerClassName: "text-center",
      type: "string",
    },
    {
      dataField: "total_price",
      caption: t("TotalCost"),
      className: "text-center",
      headerClassName: "text-center",
      type: "string",
    },
    {
      dataField: "delievry_status",
      caption: t("Action"),
      className: "text-center",
      headerClassName: "text-center",
      type: "dropdown",
      options: [
        // { label: t("Select Action"), id: "" },
        { label: t("Accept"), value: "accept", id: "1" },
        { label: t("Refuse"), value: "refuse", id: "2" },
        { label: t("Send"), value: "send", id: "3" },
      ],
      elem: (text) => (
        <span className={`${getDeleiveryStatusTextColor(text)}`}>
          {t(text)}
        </span>
      )
    },
    {
      dataField: "action",
      caption: "",
      className: "text-center",
      headerClassName: "text-center",
      active : true,
      type: "customRender",
      render: (col, it) => (
        <img
          src={EyeIcon}
          className="cursor-pointer"
          alt="..."
          onClick={showRow(it)}
        />
      ),
    }
  ];

  useEffect(() => {
    if (isRestaurantLoaded && !hasRetaurants) { 
      setCurrentItems(orderData?.purchase_orders);
      setProviders(providersData);
      return;
    }
  }, [selectedRestaurantId, isRestaurantLoaded, hasRetaurants, currentItems]);

  useEffect(() => {
    if (!isMountLoaded || selectedRestaurantId === "") {
      let updatedItems = [...originalItems];
      // Filter by provider
      if (selectedProvider) {
        const providerName = selectedProvider.name.toLowerCase();
        updatedItems = updatedItems.filter(
          (item) => item.provider.name.toLowerCase() === providerName
        );
      }
  
      // Filter by search term
      if (fd.search) {
        updatedItems = updatedItems.filter((item) =>
          item.order_number.toLowerCase().includes(fd.search.toLowerCase())
        );
      }
  
      // Sort by specified field
      if (fd.sortby) {
        updatedItems.sort((a, b) => {
          const aValue = a[fd.sortby]?.toString().toLowerCase();
          const bValue = b[fd.sortby]?.toString().toLowerCase();
  
          if (aValue < bValue) return -1;
          if (aValue > bValue) return 1;
          return 0;
        });
      }
  
      setCurrentItems(updatedItems);
      return;
    }
  }, [
    fd.search,
    fd.sortby,
    fd.orderBy,
    selectedRestaurantId,
    selectedProvider,
    originalItems
  ]);

  const showRow = (row) => () => {
    setSelectedIngredient(row);
    setModalShow(true);
  };

  const onRowSelectChanged = (col, rowData) => (ev) => {
    const result = col.options.find((o) => o.id === ev);
    if (!result) {
      return;
    }
    const newFilteredData = cloneDeep(currentItems);
    const isExist = newFilteredData.find((f) => f.id === rowData.id);
    if (isExist) {
      set(isExist, col.dataField, result.value);
      isExist.state = "update";
      setCurrentItems(newFilteredData);
    }
  };

  const onSearchChange = (ev) => {
    setfd({ ...fd, search: ev.target.value });
  };

  const selectAllRows = ({ target: { checked } }) => {
    setSelectedRows(() => (checked ? [...currentItems.map((f) => f.id)] : []));
  };

  const selectChange = (it) => ({ target: { checked } }) => {
      const newSelectedProducts = JSON.parse(JSON.stringify(selectedRows));
      if (checked) {
        setSelectedRows(() => [...newSelectedProducts, it.id]);
      } else {
        setSelectedRows(() => newSelectedProducts.filter((p) => p !== it.id));
      }
    };

  // Invoke when user click to request another page.
  const handlePageClick = async (event) => {
    try {
    //   const result = await getOrders(event.selected + 1);
    //   result.purchase_orders.forEach((value) => {
    //     value.delievry_status = value.is_delivered
    //       ? "Delivered"
    //       : "Not Delivered";
    //   });
    //   setCurrentItems(() => result.purchase_orders);
    //   setPageCount(result.total_pages);
    //   setCurrentPage(event.selected);
    } catch (error) {
      setError(DEFAULT_ERROR_MESSAGE);
    }
  };

  const onUpdateRow = (orderData) => {
    setSelectedOrder(orderData);
    setOrderDetailsModalShow(true);
  };

  const onReportRow = (orderData) => {
    setSelectedOrder(orderData);
  };

  const onModalHide = () => {
    setModalShow(false);
    setSelectedIngredient(null)
  };

  return (
    <div className="my-orders">
      <CustomModal
        show={modalShow}
        onHide={onModalHide}
        data={{ ...state, ...(selectedIngredient && { selectedIngredient }) }}
        className=""
      />
      <Row>
        <Col className="sort-container d-flex justify-content-end">
          <input
            type="search"
            className="search-input me-3"
            placeholder={t("Search")}
            onChange={onSearchChange}
          />

          <SortFilter
            cols={tableColumns.slice(0, 3)}
            fd={fd}
            setfd={setfd}
            rootClassName="sort-filter ps-3"
          />
        </Col>
      </Row>

      <Row className="mt-3 table-container">
        <CustomTable
          columns={tableColumns}
          data={currentItems}
          selectChange={selectChange}
          selectedProducts={selectedRows}
          selectAllProducts={selectAllRows}
          onRowSelectChanged={onRowSelectChanged}
          onUpdateRow={onUpdateRow}
          onReportRow={onReportRow}
        />

        <Row>
          <div className="d-flex justify-content-end mt-2">
            <ReactPaginate
              nextLabel={`${t("Next")}   >`}
              onPageChange={handlePageClick}
              forcePage={currentPage}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel={`<   ${t("Back")}`}
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </div>
        </Row>
      </Row>
    </div>
  );
}

export default MyOrders;
