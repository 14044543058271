import ArrowUp from "assets/images/icon/arrow-up-green.svg";
import ArrowDown from "assets/images/icon/arrow-down-red.svg";
import { getRandomNumber } from "views/occupancy/data";

export function getFinanceColumns(
  t,
  currencySymbol,
  hasRetaurants,
  isRestaurantLoaded,
  mealsLength,
  totalActualRevenue
) {
  const arr = [];

  return [
    {
      dataField: "name",
      caption: t("Meal"),
      className: "fw-bold",
      style: { width: "150px" },
      headerStyle: { width: "150px" },
    },
    {
      dataField: "qty",
      caption: t("SoldQty"),
      className: "text-center",
      headerClassName: "text-center",
    },
    {
      dataField: "unit_cost",
      caption: `${t("UnitCost")} (${currencySymbol})`,
      className: "text-center",
      headerClassName: "text-center",
    },
    {
      dataField: "total_cost",
      caption: `${t("TotalCost")} (${currencySymbol})`,
      className: "text-center",
      headerClassName: "text-center",
    },
    {
      dataField: "actual_revenue",
      caption: `${t("ActualRevenue")} (${currencySymbol})`,
      className: "text-center",
      headerClassName: "text-center",
    },
    {
      dataField: "predicted_revenue",
      caption: `${t("PredictedRevenue")} (${currencySymbol})`,
      className: "text-center",
      headerClassName: "text-center",
      type: "customRender",
      render: (column, item) => {
        if (item.name == "Total") {
          return (
            <label>
              {parseFloat(item?.predicted_revenue)?.toFixed(2)}
            </label>
          );
        }
        // return (
        //   <label>
        //     {parseFloat(item?.predicted_revenue)}
        //   </label>
        // );
      },
    },
    {
      dataField: "accuracy_revenue",
      caption: `${t("Accuracy")} (%)`,
      className: "text-center",
      headerClassName: "text-center",
      type: "customRender",
      render: (column, item) => {
        if (item.name == "Total") {
          const predictedRevenue = item?.predicted_revenue ?? 0;
          const actualRevenue = totalActualRevenue ?? item?.actual_revenue;
        //   const total = (actualRevenue !== 0 && predictedRevenue !== 0) ? 
        //  (1-Math.abs(1-(predictedRevenue) / actualRevenue)) * 100 : 0;
        const total = 
          ((actualRevenue !== 0 && predictedRevenue !== 0) && (actualRevenue - predictedRevenue) < actualRevenue) 
          ? (1 - Math.abs((actualRevenue - predictedRevenue) / actualRevenue)) * 100 
          : 0;
          // Check for NaN
          if (isNaN(total)) {
            return null; 
          }
          return (
            <label className={total >= 75 ? "text-green" : total >= 65 ? "text-orange" : "text-danger"}>
              {parseFloat(total)?.toFixed(2)}
            </label>
          );
        }
        // return (
        //   <label className={item.accuracy_revenue >= 75 ? "text-green" : item.accuracy_revenue >= 65 ? "text-orange" : "text-danger"}>
        //     {parseFloat(item?.accuracy_revenue)}
        //   </label>
        // );
      },
    },
    {
      dataField: "unit_margin",
      caption: `${t("UnitMargin")} (%)`,
      className: "text-center",
      headerClassName: "text-center",
      type: "customRender",
      render: (column, item) => {
        let percentage = 0;
        let total_margin = 0;
        if (item.unit_margin && item.unit_price && item.name !== "Total") {
          const revenue = item.actual_revenue ?? item.predicted_revenue;
          percentage = Number(
            (((Number(revenue) - Number(item.total_cost)) / Number(revenue)) * 100)
          ).toFixed(2);
          percentage = parseFloat(+percentage);
          arr.push(percentage);
        }

        if (item.name == "Total") {
          let revenue = item.actual_revenue && item.actual_revenue !== 0 ? item.actual_revenue : item.predicted_revenue;
          total_margin = (parseFloat((revenue - item.total_cost) / revenue)) * 100
        }
        if (isRestaurantLoaded && !hasRetaurants) {
          percentage = getRandomNumber(20, 80);
        }
        const increased = parseFloat(percentage) > 30;
        if (item?.unit_margin > 100) {
          return (
            <label
              className={item.unit_margin > 30 ? "text-green" : "text-danger"}
            >
              {item.unit_margin}
              <img
                className="change-arrow-icon"
                src={item.unit_margin > 30 ? ArrowUp : ArrowDown}
              />
            </label>
          );
        }
        if (total_margin > 0 && item.name == "Total") {
          return (
            <label
              className={
                parseFloat(total_margin) * 0.3 > 30 ? "text-green" : "text-danger"
              }
            >
              {total_margin?.toFixed(2)}
              <img
                className="change-arrow-icon"
                src={parseFloat(total_margin) * 0.3 > 30 ? ArrowUp : ArrowDown}
              />
            </label>
          );
        }
        return (
          <label className={increased ? "text-green" : "text-danger"}>
            {percentage}
            <img
              className="change-arrow-icon"
              src={increased ? ArrowUp : ArrowDown}
            />
          </label>
        );
      },
    },
    {
      dataField: "action",
      caption: "",
      className: "text-center",
      headerClassName: "text-center",
    },
  ];
}

export const generateDemoTableData = (financeData = null, selectedMealIds = [], isRestaurantLoaded, hasRetaurants, setFilteredTableData, setTotalEarnings) => {
  if (financeData && isRestaurantLoaded && !hasRetaurants) {
    const accuracyArr = financeData
    .filter(item => selectedMealIds.length === 0 || selectedMealIds.includes(item.id))
    .map((item) => {
      const predictedRevenue = item?.predicted_revenue ?? 0;
      const actualRevenue = item?.actual_revenue ?? 0;

      // const accuracy_revenue = (actualRevenue !== 0 && predictedRevenue !== 0)
      //   ? Math.abs(100 - (Math.abs((actualRevenue - predictedRevenue) / actualRevenue) * 100))
      //   : 0;
      const accuracy_revenue = 
        ((actualRevenue !== 0 && predictedRevenue !== 0) && (actualRevenue - predictedRevenue) < actualRevenue) 
        ? (1 - Math.abs((actualRevenue - predictedRevenue) / actualRevenue)) * 100 
        : 0;

      return {
        ...item,
        accuracy_revenue: accuracy_revenue.toFixed(2)
      };
    });

    setFilteredTableData(accuracyArr);

    setTotalEarnings(() =>
      accuracyArr?.map((m) => ({
        name: m.name,
        y: Number((m.qty * m.unit_price)?.toFixed(2)),
      }))
    );
    return;
  }
};