import { useState, useEffect } from 'react';
import ReactPaginate from "react-paginate";
import { Row, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useUserData } from "contexts/AuthContextManagement";
import { INGREDIENT_CATEGORIES } from "../utils";
import { useFilterData } from "contexts/FilterContextManagment";
import request from "services/request";
import UploadModal from "../categories/uploadModel";
import IngredientCard from '../categories/IngredientCard';
import CategoryCard from '../categories/CategoryCard';
import useFetch from "customHooks/useFetch";

import { useLoading } from "contexts/LoadingContextManagement";
import { DEFAULT_ERROR_MESSAGE } from "common/constants";

function Providers() {
  const { t } = useTranslation();
  const { selectedRestaurantId, setSampleRestaurantModal } = useUserData();
  const { showProvidersList, setShowProvidersList, setShowCategoriesList, updateInventorySelectedCategories } = useFilterData();
  const { setError } = useLoading();

  const [providerIds, setProviderId] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [ingredients, setIngredients] = useState([]);
  const [isCategoryView, setIsCategoryView] = useState(showProvidersList);
  const [stocksLoading, setStocksLoading] = useState(true);
  const [isModal, setIsModal] = useState(false); 

  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const { data: providersData } = useFetch('providers/provider-with-ingredient-categories', {
    restaurant_id: selectedRestaurantId,
  });

  useEffect(() => {
    setIsCategoryView(showProvidersList);
    if(isCategoryView){
      updateInventorySelectedCategories("");
      setShowCategoriesList(true);
    }
  }, [showProvidersList]);

  const calculateStock = (ingredient) => {
    const defaultProvider = ingredient.providers?.find(p => p.is_default);
    const conditioningQuantity1 = parseFloat(defaultProvider?.conditioning_quantity_1 || 1);
    const conditioningQuantity2 = parseFloat(defaultProvider?.conditioning_quantity_2 || 1);
    const recipeUnitQuantity = parseFloat(defaultProvider?.recipe_unit_quantity || 1);
  
    const unitQuantity = conditioningQuantity1 * conditioningQuantity2 * recipeUnitQuantity;
    const totalStock = parseFloat(ingredient?.stock?.unit_stock ?? 0);
  
    let packages = 0;
    let bags = 0;
    let pieces = 0;
  
    if (totalStock >= unitQuantity) {
      packages = Math.floor(totalStock / unitQuantity);
    }
    let remainingStock = totalStock % unitQuantity;
  
    if (defaultProvider?.conditioning_quantity_2 !== null && remainingStock >= conditioningQuantity2) {
      bags = Math.floor(remainingStock / conditioningQuantity2);
      remainingStock = remainingStock % conditioningQuantity2;
    }
  
    pieces = remainingStock;
  
    return { packages, bags, pieces };
  };

  const getIngredientsStock = async (category, providerIds, page = 1) => {
    setSelectedCategory(category);
    updateInventorySelectedCategories({ category: category.label, color: category.color });
    const payload = {
      restaurant_id: selectedRestaurantId,
      provider_id: providerIds,
      categories: category?.value,
      limit: 12,
      page,
    };
    try {
      const response = await request.get('stocks/inventory', payload);
      const processedIngredients = response?.ingredient_stock?.map((ingredient) => {
        const stockValues = calculateStock(ingredient);
        return {
          ...ingredient,
          ...stockValues,
          original_stock: ingredient?.stock ? ingredient.stock.unit_stock ?? 0 : 0
        };
      });
      setIngredients(processedIngredients);
      setPageCount(response?.total_pages);
      setCurrentPage(0);
      setStocksLoading(false);
      setIsCategoryView(false);
      setShowProvidersList(false);
    } catch (error) {
      setIngredients([]);
      setIsCategoryView(false);
      setShowProvidersList(false);
      console.error('Error fetching ingredients:', error);
    }
  };

  const handlePageClick = async (ev) => {
    try {
      await getIngredientsStock(selectedCategory, providerIds, ev.selected + 1);
      setCurrentPage(ev.selected);
    } catch (error) {
      console.log(error);
      setError(DEFAULT_ERROR_MESSAGE);
    }
  };

  const updateBags = (ingredient, increment) => {
    setIngredients((prevIngredients) =>
      prevIngredients.map((item) =>
        item.id === ingredient.id
          ? {
              ...item,
              bags: Math.max(0, (item.bags || 0) + increment),
              stock: {
                ...item.stock,
                unit_stock: Math.max(0, item.stock.unit_stock + increment * (ingredient.providers?.find(p => p.is_default)?.recipe_unit_quantity || 1)),
              }
            }
          : item
      )
    );
  };

  const updatePackages = (ingredient, increment) => {
    setIngredients((prevIngredients) =>
      prevIngredients.map((item) =>
        item.id === ingredient.id
          ? {
              ...item,
              packages: Math.max(0, (item.packages || 0) + increment),
              stock: {
                ...item.stock,
                unit_stock: Math.max(
                  0,
                  ((item.stock?.unit_stock ?? 0) +
                    increment * 
                    (ingredient.providers?.find(p => p.is_default)?.recipe_unit_quantity || 1) *
                    (ingredient.providers?.find(p => p.is_default)?.conditioning_quantity || 1)
                  )
                ),
              }
            }
          : item
      )
    );
  };
  
  const updatePieces = (ingredient, increment) => {
    setIngredients((prevIngredients) =>
      prevIngredients.map((item) =>
        item.id === ingredient.id
          ? {
              ...item,
              pieces: Math.max(0, (item.pieces || 0) + increment),
              stock: {
                ...item.stock,
                unit_stock: Math.max(0, item.stock.unit_stock + increment),
              }
            }
          : item
      )
    );
  };
  
  const submitStock = async () => {
    if (!selectedRestaurantId) {
      setSampleRestaurantModal(true);
      return;
    }
  
    const updatedData = ingredients.map((ingredient) => {
      const defaultProvider = ingredient.providers?.find(p => p.is_default);
      const conditioningQuantity = parseFloat(defaultProvider?.conditioning_quantity || 1);
      const recipeUnitQuantity = parseFloat(defaultProvider?.recipe_unit_quantity || 1);
  
      // Calculate total pieces correctly
      const totalPieces = (ingredient.packages * conditioningQuantity * recipeUnitQuantity) + 
                          (ingredient.bags * recipeUnitQuantity) + 
                          ingredient.pieces;
      
      const difference = totalPieces - ingredient.original_stock;
  
      return {
        ingredient_id: ingredient.id,
        restaurant_id: selectedRestaurantId,
        unit_stock: totalPieces,
        difference,
        expiry: ingredient.expiry || "good",
      };
    });

    // Filter out ingredients with a difference of 0
    const filteredData = updatedData.filter(item => item.difference !== 0);

    if (!filteredData.length) {
      return;
    }
  
    try {
      setStocksLoading(true) ;
      const result = await request.post("/stocks", { stocks: filteredData });
      if (result.status !== 200) {
        throw new Error((await result.json())?.msg);
      }
      setStocksLoading(false);
      setIsModal(true);
    } catch (error) {
      console.log(error);
      setStocksLoading(false);
    }
  };

  const cancelStock = () => {
    setIsCategoryView(true);
    updateInventorySelectedCategories("");
  };

  return (
    <>
      <UploadModal
        show={isModal}
        onHide={() => setIsModal(false)}
        title="Stock updated !"
        subTitle="Your stock has been successfully updated."
      />
      <>
        {isCategoryView ? (
          providersData?.providers?.filter(provider => 
            INGREDIENT_CATEGORIES.some(category => 
              category.value.some(catValue => provider.ingredient_categories.includes(catValue))
            )
          ).map((provider) => (
            <div key={provider.id} className="provider-container">
              <div className="provider-header">
                <div className="provider-circle">
                  {provider?.name?.[0]?.toUpperCase()}
                </div>
                <h2 className="provider-name">{provider?.name.toUpperCase()}</h2>
                <div className="provider-line"></div>
              </div>
              <Row>
                {INGREDIENT_CATEGORIES.filter(category => 
                  category.value.some(catValue => provider?.ingredient_categories?.includes(catValue))
                ).map((filteredCategory, i) => (
                  <CategoryCard
                    key={i}
                    category={filteredCategory}
                    providerId={provider.id}
                    getIngredientsStock={() => {
                      setProviderId(provider?.id);
                      getIngredientsStock(filteredCategory, provider?.id);
                    }}
                  />
                ))}
              </Row>
            </div>
          ))
        ) : (
          <>
            <div className='card_wrapper'>
              {ingredients.length > 0 ? (
                ingredients.map((ingredient, i) => (
                  <IngredientCard
                    key={i}
                    ingredient={ingredient}
                    updatePackages={updatePackages}
                    updateBags={updateBags}
                    updatePieces={updatePieces}
                  />
                ))
              ) : (
                <div className='no_ingredients'>{t('NoIngredientsAvailable')}</div>
              )}
            </div>

            <div className="d-flex justify-content-end mt-3">
              <ReactPaginate
                nextLabel={`${t("Next")}   >`}
                onPageChange={handlePageClick}
                forcePage={currentPage}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                previousLabel={`<   ${t("Back")}`}
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            </div>

            {ingredients.length > 0 && (
              <div className="button-wrapper">
                <Button size="lg" className="button cancel" onClick={cancelStock}>{t("Back")}</Button>
                <Button size="lg" className="button save" onClick={submitStock}>{t("SaveChanges")}</Button>
              </div>
            )}
          </>
        )}
      </>
    </>
  );
}

export default Providers;