import React, { useState } from "react";
import moment from "moment";

import LeftSide from "./stock";
import "../index.scss";

function Stock() {
  const [clear, setClear] = useState(false);
  const [formData, setformData] = useState({
    start_date: moment().format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
    products: [],
  });


  return (
    <>
      <LeftSide formData={formData} clear={clear}/>
    </>
  );
}

export default Stock;
