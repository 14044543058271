import React, { useState } from "react";
import moment from "moment";

import LeftSide from "./leftSide";
import RightSide from "./rightSide";

import "../index.scss";

function Sale() {
  const [formData, setFormData] = useState({
    start_date: moment().format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD")
  });

  const onApply = (params) => setFormData({ ...params });

  return (
    <>
      <LeftSide formData={formData} />
      <RightSide onApply={onApply} formData={formData} />
    </>
  );
}

export default Sale;
