import React, { useState, useEffect } from "react";
import { Modal, Card, Row, Button } from "react-bootstrap";
import { set } from "lodash";
import { useLocation } from "react-router-dom";
import { cloneDeep, customToFixed } from "common/utils.ts";
import CustomTable from "components/customTable/index.tsx";
import { useUserData } from "contexts/AuthContextManagement";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

//get status color for deleivery status
const getDeleiveryStatusTextColor = (text) => {
  let str = "text-";
  if (text === "Delivered") {
    str += "delivered";
  } else if (text === "Not Delivered") {
    str += "not-delivered";
  }
  return str;
};

function OrderDetailsModal(props) {
  const { t } = useTranslation();
  const { orderDetails, onUpdateDelieveryStatus } = props;
  const [tableData, setTableData] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [changedRows, setChangedRows] = useState([]);
  const location = useLocation();
  const { selectedRestaurantId, setSampleRestaurantModal } = useUserData();

  //Order Table columns
  const tableColumns = [
    {
      caption: t("Reference"),
      dataField: "provider_reference_name",
      type: "string",
    },
    {
      caption: t("Conditioning") + " 1",
      dataField: "conditioning_name_1",
      className: "text-center",
      headerClassName: "text-center",
      type: "string",
    },
    {
      caption: t("UnitCost"),
      dataField: "product_price",
      type: "number",
      className: "text-center",
      headerClassName: "text-center",
    },
    {
      caption: t("Quantity"),
      dataField: "product_quantity",
      type: "number",
      className: "text-center",
      headerClassName: "text-center",
      columnType: "inputNumber",
    },
    {
      caption: t("DelievryStatus"),
      dataField: "delievry_status",
      type: "dropdown",
      className: "text-center",
      headerClassName: "text-center",
      options: [
        { label: t("Delivered"), id: "delivered" },
        { label: t("Not Delivered"), id: "not_delivered" },
      ],
      elem: (text) => (
        <span className={`${getDeleiveryStatusTextColor(text)}`}>
          {t(text)}
        </span>
      ),
    },
    {
      caption: t("TotalCost"),
      dataField: "total_cost",
      type: "number",
      className: "text-center",
      headerClassName: "text-center",
    },
  ];

  //On Table Row dataChange
  const onRowSelectChanged = (col, rowData) => (ev) => {
    const result = col.options.find((o) => o.id === ev);
    if (!result) {
      return;
    }
    const mapping = {
      delivered: "Delivered",
      not_delivered: "Not Delivered",
    };
    const newFilteredData = cloneDeep(tableData);
    const isExist = newFilteredData.find((f) => f.id === rowData.id);
    if (isExist) {
      set(isExist, col.dataField, mapping[result.id]);
      isExist.state = "update";
      setTableData(newFilteredData);

      let isUpdated = changedRows.find((c) => c.id === rowData.id);
      if (isUpdated) {
        let _changedRows = [...changedRows];
        _changedRows.every((value) => {
          if (value.id === rowData.id) {
            value.is_delivered = mapping[result.id] === "Delivered";
            return false;
          }
          return true;
        });
        setChangedRows(_changedRows);
      } else {
        let _data = {
          id: rowData.id,
          is_delivered: mapping[result.id] === "Delivered",
        };
        setChangedRows([...changedRows, _data]);
      }
    }
  };

  useEffect(() => {
    orderDetails.purchase_items.forEach((p) => {
      p.total_cost = Number((Number(p.product_price) * p.product_quantity).toFixed(2));
      p.delievry_status = p.is_delivered ? "Delivered" : "Not Delivered";
    });

    setTableData(orderDetails.purchase_items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectChange =
    (it) =>
      ({ target: { checked } }) => {
        const newSelectedProducts = cloneDeep(selectedProducts);
        if (checked) {
          setSelectedProducts(() => [...newSelectedProducts, it.id]);
        } else {
          setSelectedProducts(() =>
            newSelectedProducts.filter((p) => p !== it.id)
          );
        }
      };

  //Select all table products
  const selectAllProducts = ({ target: { checked } }) => {
    if (checked) {
      setSelectedProducts(() => [...tableData.map((t) => t.id)]);
    } else {
      setSelectedProducts(() => []);
    }
  };

  const tableInputChange =
    (it) =>
      ({ target: { name, value } }) => {
        const newTableData = JSON.parse(JSON.stringify(tableData));
        const isExist = newTableData.find((f) => f.id === it.id);
        if (isExist) {
          set(isExist, name, value);
          set(isExist, "total_cost", Number((Number(value) * Number(it.product_price)).toFixed(2)));
          isExist.state = "update";
          setTableData(newTableData);

          // if (Boolean(value)) {
            let isUpdated = changedRows.find((c) => c.id === it.id);
            const isDelivered = it.delievry_status === "Delivered";
            if (isUpdated) {
              let _changedRows = [...changedRows];
              _changedRows.every((item) => {
                if (item.id === it.id) {
                  item.product_quantity = value;
                  item.is_delivered = isDelivered;
                  return false;
                }
                return true;
              });
              setChangedRows(_changedRows);
            } else {
              let _data = {
                id: it.id,
                product_quantity: value,
                is_delivered: isDelivered,
              };
              setChangedRows([...changedRows, _data]);
            }
          // }
        }
      };

  //Select product cost sum
  const selectedProdcutsSum = () => {
    const items = selectedProducts.map((id) =>
      tableData.find((t) => t.id === id)
    );
    return Number(getSum(items).toFixed(2)) || customToFixed(getSum(items) || 0);
  };

  const getSum = (rowsItem = []) => {
    return rowsItem.reduce((previousValue, currentValue) => {
      return previousValue + currentValue?.total_cost;
    }, 0);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      fullscreen={true}
      centered
    >
      <Modal.Body className="order-details">
        <section className={`maincontent h-10 maincontent-collapsed`}>
          <Card className="p-5 container">
            <Row className="mb-5">
              <label className="back" onClick={() => props.onHide()}>
                {"<"} {t("Back")}
              </label>
            </Row>
            <Row>
              <h3 className="heading-text">
                {location?.state?.products?.provider?.name?.toUpperCase()}
                {t("OrderDetails")}
              </h3>
            </Row>
            <CustomTable
              columns={tableColumns}
              data={tableData}
              selectChange={selectChange}
              selectedProducts={selectedProducts}
              selectAllProducts={selectAllProducts}
              onInputChange={tableInputChange}
              onRowSelectChanged={onRowSelectChanged}
            />
            <div className="d-flex justify-content-end mt-3 me-2 selected-prod-div">
              {Boolean(selectedProducts.length) && (
                <div className="d-flex flex-column me-3">
                  <label className="d-flex justify-content-end tc">
                    Total cost
                  </label>
                  <label className="d-flex justify-content-end fw-bold sum">
                    {selectedProdcutsSum()} EUR
                  </label>
                </div>
              )}
              <Button
                className="float-end place-order-btn me-3"
                onClick={() => {
                  if (selectedRestaurantId == "") {
                    setSampleRestaurantModal(true);
                    return;
                  } else if(changedRows.some(item => item.product_quantity === "")) {
                    toast.error("Product quantity can't be empty")
                  } else {
                    onUpdateDelieveryStatus(changedRows, "items");
                  }
                }}
              >
                {t("SaveChanges")}
              </Button>
            </div>
          </Card>
        </section>
      </Modal.Body>
    </Modal>
  );
}

export default OrderDetailsModal;
